<script>
import Layout from "../../../layouts/main";

import PurchasesAgents from "./PurchasesAgents.vue";

export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    Layout,
    PurchasesAgents,
  },
  data() {
    return {
      sale_orders: [],

      PurchasesAgents: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchPurchasesAgents: null,
      PurchasesAgentsSearchMode: false,
      searchModel: "",
      company_id: "",
    };
  },
};
</script>

<template>
  <Layout>
    <purchases-agents />
  </Layout>
</template>

<style scoped>
.btn-close {
  position: relative;
  font-size: 15px;
}
</style>
