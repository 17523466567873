<script>
// import Layout from "../../../layouts/main";

import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description"
      },
    ],
  },
  components: {
    // Layout,
    PageHeader,
  },
  data() {
    return {
      sale_orders: [],
      PurchasesAgents: [],
      accountsPayable: [],
      advancedPayments: [],
      currencies: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchPurchasesAgents: null,
      PurchasesAgentsSearchMode: false,
      searchModel: "",
    };
  },
  computed: {

  },
  methods: {
    addPurchasesAgents() {
      let locale = this.$i18n.locale;
      console.log("**********####this.advancedPayments", this.advancedPayments);
      var data = {
        title: this.$t("purchases.purchases_agents.add"),
        inputs: [
          {
            model: "type",
            type: "select",
            label: this.$t("popups.type"),
            "options":[
              {
                value:"export",
                label:(this.$i18n.locale == 'ar' ? "محلي" :"Local")
              },
              {
                value:"import",
                label:(this.$i18n.locale == 'ar' ? "خارجي" :"Abroad")
              },
            ]

          },
          {
            model: "name",
            type: "text",
            label: this.$t("purchases.purchases_agents.name"),
          },
          {
            model: "tel",
            type: "tel",
            label: this.$t("purchases.purchases_agents.tel"),
          },
          {
            model: "email",
            type: "email",
            label: this.$t("purchases.purchases_agents.email"),
          },
          {
            model: "tax_number",
            type: "text",
            label: this.$t("purchases.purchases_agents.tax_number"),
          },
          {
            model: "currency_id",
            type: "select",
            options: (() => {
              return this.currencies?.map((data) => {
                return {
                  value: data.id,
                  label: locale == "ar" ? data.name : data.name_en,
                };
              });
            })(),
            label: "purchases.purchases_agents.currency",
          },
          {
            model: "payable_subaccount_id",
            type: "select",
            options: (() => {
              return this.accountsPayable?.map((one) => {
                return {
                  value: one.id,
                  label: locale == "ar" ? one.name : one.name_en,
                };
              });
            })(),
            label: "purchases.purchases_agents.payable_account",
          },
          {
            model: "prepayments_subaccount_id",
            type: "select",
            options: (() => {
              return this.advancedPayments?.map((one) => {
                return {
                  value: one.id,
                  label: locale == "ar" ? one.name : one.name_en,
                };
              });
            })(),
            label: "purchases.purchases_agents.prepayments_account",
          },
          {
            model: "evaluation",
            type: "text",
            label: this.$t("purchases.purchases_agents.evaluation"),
          },
          {
            model: "addr",
            type: "ckeditor",
            label: this.$t("agents.address"),
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "primary",
            handler: (obj) => {
              if(!obj.evaluation)
                obj.evaluation = null
              this.http.post("purchases-agents", obj).then((res) => {
                if (res.status) {
                  this.responseAlert.fire(
                    this.$t("popups.added"),
                    this.$t("popups.done"),
                    "success"
                  );
                  this.get(this.page);
                }

              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      console.log("DDDDD",data)
      this.popup.modal(data);
    },
    editPurchasesAgents(app) {
      let locale = this.$i18n.locale;
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: this.$t("purchases.purchases_agents.name"),
            value: app.name,
          },
          {
            model: "tel",
            type: "tel",
            label: this.$t("purchases.purchases_agents.tel"),
            value: app.tel,
          },
          {
            model: "email",
            type: "email",
            label: this.$t("purchases.purchases_agents.email"),
            value: app.email,
          },
          {
            model: "tax_number",
            type: "text",
            label: this.$t("purchases.purchases_agents.tax_number"),
            value: app.tax_number,
          },
          {
            model: "currency_id",
            type: "select",
            value: app.currency_id,
            options: (() => {
              return this.currencies?.map((data) => {
                return {
                  value: data.id,
                  label: locale == "ar" ? data.name : data.name_en,
                };
              });
            })(),
            label: "purchases.purchases_agents.currency",
          },
          {
            model: "payable_subaccount_id",
            type: "select",
            value: app.payable_subaccount_id,
            options: (() => {
              return this.accountsPayable?.map((data) => {
                return {
                  value: data.id,
                  label: locale == "ar" ? data.name : data.name_en,
                };
              });
            })(),
            label: "purchases.purchases_agents.payable_account",
          },
          {
            model: "prepayments_subaccount_id",
            type: "select",
            value: app.prepayments_subaccount_id,
            options: (() => {
              return this.advancedPayments?.map((data) => {
                return {
                  value: data.id,
                  label: locale == "ar" ? data.name : data.name_en,
                };
              });
            })(),
            label: "purchases.purchases_agents.prepayments_account",
          },
          {
            model: "evaluation",
            type: "text",
            label: this.$t("purchases.purchases_agents.evaluation"),
            value: app.evaluation,
          },
          {
            model: "addr",
            type: "ckeditor",
            label: this.$t("agents.address"),
            value: app.addr,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "primary",
            handler: (obj) => {
              this.http.put("purchases-agents", app.id, obj).then((res) => {
                if (res.status) {
                  this.responseAlert.fire(
                    this.$t("popups.updated"),
                    this.$t("popups.done"),
                    "success"
                  );
                  this.searchModel = ""
                  this.PurchasesAgentsSearchMode = false
                  this.get(this.page);
                } else {
                  this.responseAlert.fire(
                    this.$t("popups.not_updated"),
                    this.$t("popups.error"),
                    "error"
                  );
                }
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };

      this.popup.modal(data);
    },
    search() {
      if (this.searchModel == "") return this.cancelappsearchMode();
      this.PurchasesAgentsSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("purchases-agents/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "name",
        })
        .then((res) => {
          this.PurchasesAgents = res.data;
          console.log("######## files", this.PurchasesAgents);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.PurchasesAgentsSearchMode = false;
      this.get(this.page);
    },
    getPurchasesAgents() {
      this.http.get("purchases-agents").then((res) => {
        this.PurchasesAgents = res.data;
      });
    },
    deletePurchasesAgents(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.http.delete("purchases-agents", app.id).then(() => {
            this.responseAlert.fire(
              this.$t("popups.deleted"),
              this.$t("popups.done"),
              "success"
            );
            this.get(this.page);
          });
        }
      });
    },
    getAccounts() {
      this.http
        .get("subledgers/get-suppliers-sub-accounts")
        .then((res) => {
          if (res.status) {
            this.accountsPayable = res.accounts_payable;
            this.advancedPayments = res.prepayments;
            console.log("this.advancedPayments",this.advancedPayments)
          }
        });
    },
    getCurrencies() {
      this.http.get("currencies/active").then((res) => {
        if (res.status) {
          this.currencies = res.data;
        }
      });
    },
    openDetails(id) {
      this.$router.push(`/purchases_agents_profile/${id}`);
    },
    get(page) {
      console.log(page);
      this.http
        .post("purchases-agents/pagination", {
          limit: this.limit,
          page: page,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.PurchasesAgents = res.data;
          this.PurchasesAgents.forEach((agent, index) => {
            let code = (this.page - 1) * this.limit + index + 1;
            agent.code =
              "SUP-" +
              (code < 10
                ? "000" + code
                : code < 100
                ? "00" + code
                : code < 1000
                ? "0" + code
                : code);
          });
        });
    },
  },
  mounted() {
    this.get(1);
    this.getAccounts();
    this.getCurrencies();
  },
};
</script>

<template>
  <div>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('purchases.purchases_agents.title')"
    />
    <row>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="search-box chat-search-box w-50">
                  <div class="position-relative">
                    <input
                      @keyup="search()"
                      type="text"
                      class="form-control fa-lg text-light"
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      :placeholder="$t('popups.search')"
                      v-model="searchModel"
                      style="background-color: #2a3042 !important"
                    />
                    <i class="bx bx-search-alt search-icon text-light"></i>
                  </div>
                </div>
              </div>
              <div
                class="col-6"
                style="display: flex; justify-content: flex-end"
              >
                <button
                  class="btn-close text-light"
                  v-if="PurchasesAgentsSearchMode"
                  @click="cancelappsearchMode()"
                ></button>
                <button
                  type="button"
                  @click="addPurchasesAgents()"
                  class="btn btn-light float-end mb-4"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>

            <div class="table-responsive">
              <table
                :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                class="table table-centered table-striped table-nowrap align-middle"
              >
                <thead>
                  <tr
                    class="text-light"
                    style="background-color: #2a3042 !important"
                  >
                    <th scope="col">#</th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.code") }}
                    </th>
                    <th scope="col">
                      {{ $t("popups.type") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.name") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.currency") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.payable_account") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.prepayments_account") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.tax_number") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.tel") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.email") }}
                    </th>
                    <th scope="col">
                      {{ $t("agents.address") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.evaluation") }}
                    </th>
                    <th scope="col">
                      {{ $t("purchases.purchases_agents.actions") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(app, index) in PurchasesAgents"
                    :key="app"
                    class="active-transaction"
                  >
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{ (index+1) }}
                    </td>
                    <td
                        data-toggle="tooltip"
                        data-placement="top"
                    >
                      {{ app?.code }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                    {{ app.type=='export' ? (this.$i18n.locale == 'ar' ? "محلي" :"Local") : (this.$i18n.locale == 'ar' ? "خارجي" :"Abroad") }}
                  </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      {{ app?.name }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{
                        $i18n.locale == "ar"
                          ? app?.currency?.name
                          : app?.currency?.name_en
                      }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{
                        $i18n.locale == "ar"
                          ? app?.payable_subaccount?.name
                          : app?.payable_subaccount?.name_en
                      }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{
                        $i18n.locale == "ar"
                          ? app?.prepayments_subaccount?.name
                          : app?.prepayments_subaccount?.name_en
                      }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{ app?.tax_number ? app?.tax_number : "-" }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{ app?.tel }}
                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{ app?.email }}
                    </td>
                    <td
                        data-toggle="tooltip"
                        data-placement="top"

                        v-html="app?.addr"
                    >

                    </td>
                    <td
                      data-toggle="tooltip"
                      data-placement="top"

                    >
                      {{ app?.evaluation }}
                    </td>

                    <td class="">
                      <router-link :to="'/purchases_agents_profile/' + app?.id">
                        <button
                          class="btn btn-info mx-1"
                          role="button"
                          data-toggle="tooltip"
                          data-placement="top"
                          :title="app.name + ' ' + $t('warehouses.profile')"
                        >
                          <i class="bx bxs-detail"></i>
                        </button>
                      </router-link>
                      <a
                        class="btn btn-primary mx-1"
                        @click="editPurchasesAgents(app)"
                        href="javascript: void(0);"
                        role="button"
                        >{{ $t("popups.edit") }}</a
                      >
                      <a
                        class="btn btn-danger"
                        href="javascript: void(0);"
                        role="button"
                        @click="deletePurchasesAgents(app)"
                        >{{ $t("popups.delete") }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end  table -->
            <!-- Button trigger modal -->

            <div>
              <!--   Apps  Pagination     -->
              <ul
                class="pagination pagination-rounded justify-content-center mb-2"
              >
                <li class="page-item" :class="{ disabled: page == 1 }">
                  <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="get(page - 1)"
                    aria-label="Previous"
                  >
                    <i class="mdi mdi-chevron-left"></i>
                  </a>
                </li>
                <li
                  class="page-item"
                  :class="{ active: p == page }"
                  v-for="p in tot_pages"
                  :key="p"
                >
                  <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="get(p)"
                    >{{ p }}</a
                  >
                </li>

                <li class="page-item" :class="{ disabled: page == tot_pages }">
                  <a
                    class="page-link"
                    href="javascript: void(0);"
                    @click="get(page + 1)"
                    aria-label="Next"
                  >
                    <i class="mdi mdi-chevron-right"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </row>
    <!--end-->
  </div>
</template>

<style scoped>
.btn-close {
  position: relative;
  font-size: 15px;
}

.active-transaction:hover {
  cursor: pointer;
  background: rgb(222, 222, 226);
}
.active-transaction {
  padding: 1rem !important;
}
td,
th {
  padding: 1rem;
}
</style>
